<template>
    <div class="inner-container">
        <div class="table-container">
            <b-table
                :fields="tableFields"
                :items="goodsData"
                responsive="true"
                bordered
                head-variant="light"
                sticky-header="true"
                no-border-collapse
            >   
                <template #head(more)>
                    <div class="text-center">
                        <i 
                            :title="getCommonText('del_selected')"
                            class="icon icon-close table-all-remove"
                            @click="deleteItem(`${selectAllGoods ? getDecText('del_all_recs') : getDecText('del_selected_recs') }`)"/>
                    </div>
                </template>
                <template #top-row="data">
                    <td></td>
                    <td class="td-numbering table-success text-center">1</td>
                    <td colspan="3" class="td-numbering table-danger text-center">2</td>
                    <td class="td-numbering table-info text-center">3</td>
                    <td class="td-numbering table-primary text-center">4</td>
                    <td class="td-numbering table-warning text-center">5</td>
                    <td class="td-numbering table-success text-center">6</td>
                    <td class="td-numbering table-danger text-center">7</td>
                    <td class="td-numbering table-info text-center">8</td>
                    <td class="td-numbering table-primary text-center">9</td>
                    <td class="td-numbering table-warning text-center">10</td>
                    <td class="td-numbering table-success text-center">11</td>
                    <td v-if="formCode !== '01-141'" class="td-numbering table-danger text-center">12</td>
                    <td class="td-numbering text-center"></td>
                </template>
                <template #head(action)>
                    <div class="text-center">
                        <b-form-checkbox 
                            v-model="selectAllGoods" 
                            @change="e => $emit('setIsAllDelete', e, goodsData)"
                            :value="true"
                            :unchecked-value="false" />
                    </div>
                </template>
                
                <template #cell(action)="data">
                    <b-form-checkbox 
                        v-model="data.item.itemToDelete"
                        @input="e => { if (!e) selectAllGoods = false; }"
                        :value="true"
                        :unchecked-value="false" />
                </template>
                <template #cell(index)="data">
                    {{ data.index + 1 }}
                </template>
                <template #cell(good_name)="data">
                    <div class="form-03149-multiselect-group">
                        <multiselect
                            v-b-popover.hover.topright="currGoodName ? currGoodName.name : null"
                            v-if="variantAttribute && data.item.isNew"
                            v-model="currGoodName"
                            style="width: 270px"
                            label="name"
                            :placeholder="getDecText('enter_name')"
                            :options="ensTruNamePrt"
                            :searchable="true"
                            :allow-empty="false"
                            :show-labels="false"
                            :internal-search="false"
                            @blur="() => ensTruNamePrt.splice(0)"
                            @search-change="(e) => $emit('searchName', e)"
                            @input="setCurrGoodDesc(data.item)"
                        >
                        <span slot="noResult">
                            {{ getDecText('name_not_found') }}
                        </span>
                        <span slot="noOptions">
                            {{ getDecText('start_typing_name') }}
                        </span>
                        </multiselect>
                        <div v-else :id="`ens-item-name-${data.item.id}`" v-bind:class="{'ens-item-non-active': !data.item.esnActive}">{{ data.item.name }}</div>
                        <b-spinner v-if="data.item.isNew && parNameLoading" small label="Small Spinner"></b-spinner>
                        <b-popover v-if="!data.item.esnActive" placement="topright" :target="`ens-item-name-${data.item.id}`" triggers="hover focus">
                            {{ getDecText('code_isnt_active') }}
                        </b-popover>
                    </div>
                    
                </template>
                <template #cell(shrt_descr)="data">
                    <b-spinner class="form-03149-spinner" v-if="data.item.isNew && descIsLoading" small label="Small Spinner"></b-spinner>
                    <multiselect
                        v-b-popover.hover.topright="data.item.currGoodDesc ? data.item.currGoodDesc.detail : null"
                        v-if="variantAttribute && data.item.ensTruName && data.item.ensTruName.length > 1"
                        v-model="data.item.currGoodDesc"
                        style="width: 270px"
                        label="detail"
                        :placeholder="getDecText('select_characteristics')"
                        :options="data.item.ensTruName"
                        :searchable="true"
                        :allow-empty="false"
                        :show-labels="false"
                        @input="descSelectedNameChanged(data.item)"
                    >
                    <span slot="noOptions">
                        {{ getDecText('select_name') }}
                    </span>
                    </multiselect>

                    <div v-else>{{ data.item.shrDescr }}</div>
                </template>
                <template #cell(additional_descr)="data">
                    <b-form-textarea v-if="variantAttribute && data.item.enstru_code"
                        style="min-height: 100px"
                        v-model.trim="data.item.add_detail"
                        @change="$emit('onDataChanged', data.item)"
                        :formatter="formatText"
                    ></b-form-textarea>
                    <div v-else>{{ data.item.add_detail }}</div>
                </template>
                <template #cell(unit)="data">
                    <multiselect
                        v-if="variantAttribute && data.item.uom && data.item.uom.length > 1"
                        style="max-width: 130px"
                        :value="data.item.uom.filter(u => u.code === data.item.unit_code)[0]"
                        @input="v => {currUnit = v; $set(data.item, 'unit_code', v.code); onChange(data.item, 'norm_per')}"
                        label="name"
                        :options="data.item.uom"
                        :searchable="true"
                        :allow-empty="false"
                        :show-labels="false"
                    >
                    </multiselect>
                    <div v-else>{{ getUnitName(data.item.unit_code) }}</div>
                </template>
                <template #cell(num_meals)="data">
                    <b-form-input v-if="variantAttribute && data.item.enstru_code"
                                  class="text-right"
                                  :value="data.item.num_meals"
                                  @change="v => {data.item.num_meals = v; onChange(data.item, 'num_meals')}"
                                  @keyup.enter.exact="keyup13"
                                  @keypress="keyPress($event, '^[0-9]+$')"
                                  @blur="inputFixed(data.item, 'num_meals', data.item.num_meals, 0)">
                    </b-form-input>
                    <div v-else class="text-right">{{ $n(data.value) }}</div>
                    <p class="goods-form-val-msg" v-if="variantAttribute && data.item.enstru_code && !data.item.nummealsPassVal">{{ getCommonText("positive") }}</p>
                </template>
                <template #cell(num_day)="data">
                    <b-form-input v-if="variantAttribute && data.item.enstru_code"
                                  class="text-right"
                                  :value="data.item.num_day"
                                  @change="v => {data.item.num_day = v; onChange(data.item, 'num_day')}"
                                  @keyup.enter.exact="keyup13"
                                  @keyup="(e) => $emit('checkNumber', e, 1, 365)"
                                  @keypress="keyPress($event, '^[0-9]+$')"
                                  @blur="inputFixed(data.item, 'num_day', data.item.num_day, 0)">
                    </b-form-input>
                    <div v-else class="text-right">{{ $n(data.value) }}</div>
                    <p class="goods-form-val-msg" v-if="variantAttribute && data.item.enstru_code && !data.item.numdayPassVal">{{ getCommonText("positive") }}</p>
                </template>
                <template #cell(norm_per)="data">
                    <b-form-input v-if="variantAttribute && data.item.enstru_code"
                                  class="text-right"
                                  :value="data.item.norm_per"
                                  @change="v => {data.item.norm_per = v; onChange(data.item, 'norm_per')}"
                                  @keyup.enter.exact="keyup13"
                                  @keypress="keyPress($event, '^\\d*\\.?\\d{0,9}$')"
                                  @blur="inputFixed(data.item, 'norm_per', data.item.norm_per, normDigits)">
                    </b-form-input>
                    <div v-else class="text-right">{{ data.value | pointToComma }}</div>
                    <p class="goods-form-val-msg" v-if="variantAttribute && data.item.enstru_code && !data.item.normperPassVal">{{ getCommonText("positive") }}</p>
                </template>
                <template #cell(price_cur)="data">
                    <b-form-input v-if="variantAttribute && data.item.enstru_code"
                                  class="text-right"
                                  :value="data.item.price_cur"
                                  @change="v => {data.item.price_cur = v; onChange(data.item, 'price_cur')}"
                                  @keyup.enter.exact="keyup13"
                                  @keypress="keyPress($event, '^\\d*\\.?\\d{0,9}$')"
                                  @blur="inputFixed(data.item, 'price_cur', data.item.price_cur, 2)">
                    </b-form-input>
                    <div v-else class="text-right">{{ $n(data.value) }}</div>
                    <p class="goods-form-val-msg" v-if="variantAttribute && data.item.enstru_code && !data.item.pricecurPassVal">{{ getCommonText("positive") }}</p>
                </template>
                <template #cell(price_indexed)="data">
                    <b-form-input v-if="variantAttribute && data.item.enstru_code && formCode !== '01-141'"
                                  v-b-popover.hover.topright="`idx: ${data.item.price_cur > 0 ? currInflIdx(data.item) : ''}% (${idxOfInflInPersent}%)`"
                                  class="text-right"
                                  :class="{'wrong_idx': currInflIdx(data.item) !== idxOfInflInPersent}"
                                  :value="data.item.price_indexed"
                                  @change="v => {data.item.price_indexed = v; onChange(data.item, 'price_indexed')}"
                                  @keyup.enter.exact="keyup13"
                                  @keypress="keyPress($event, '^\\d*\\.?\\d{0,9}$')"
                                  @blur="inputFixed(data.item, 'price_indexed', data.item.price_indexed, 2)">
                    </b-form-input>
                    <div 
                        v-else 
                        class="text-right"
                        v-b-popover.hover.topright="`idx: ${data.item.price_cur > 0 ? currInflIdx(data.item) : ''}% (${idxOfInflInPersent}%)`"
                        :class="{'wrong_idx': variantAttribute && currInflIdx(data.item) !== idxOfInflInPersent}"
                    >{{ $n(data.value) }}</div>
                    <p class="goods-form-val-msg" v-if="variantAttribute && data.item.enstru_code && !data.item.priceidxedPassVal">{{ getCommonText("positive") }}</p>
                </template>
                <template #cell(norm_money)="data">
                    <div class="text-right">{{ data.value | pointToComma }}</div>
                </template>
                <template #cell(year_value)="data">
                    <div class="text-right">{{ $n(data.value) }}</div>
                </template>
                <template #cell(total)="data">
                    <div class="text-right">{{ $n(data.value) }}</div>
                </template>
                <template #cell(files)="data">
                    <span
                        class="blue pointer underline"
                        @click="onFilesClick(data.item)"
                    >({{data.item.file_hash ? data.item.file_hash.split(';').length : 0}})</span>
                </template>
                <template #cell(more)="data">
                        <i class="icon icon-clear table-remove" :disabled="goodsAreSaving" v-if="variantAttribute" @click="deleteItem(`${getDecText('del_curr_rec')}`, data.item, data.index)"></i>
                </template>
                
                <template #bottom-row="data">
                    <td class="text-left" colspan="6">{{ getCommonText("total") }}</td>
                    <td class="text-right">{{ $n(formCode === '01-141' ? totalMaxDays : totalNumMeals) }}</td>
                    <td class="text-right">{{ $n(formCode === '01-141' ? totalNumMeals : totalAvgDays) }}</td>
                    <td v-if="formCode !== '01-141'"></td>
                    <td colspan="2"></td>
                    <td class="text-right">{{ totalNormMoney | pointToComma }}</td>
                    <td></td>
                    <td class="text-right">{{ $n(totalSpend) }}</td>
                    <td colspan="2"></td>
                </template>
                
            </b-table>

            <modal-files-management 
                ref="modalFilesManagement"
                :allFiles="allFiles"
                :variantAttribute="variantAttribute"
                @showFileUploadModal="showFileUploadModal"
                @setNewFileHash="setNewFileHash"
                @triggDownloadFile="triggDownloadFile"
            ></modal-files-management>
            
        </div>
    </div>
</template>

<script>
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import ModalFilesManagement from './modal-files-management.vue';
import BpI18nHandlerMixin from '../mixins/bp-i18n-handler-mixin';
import Decimal from 'decimal.js';

export default {
    name: 'FormTemplate4',
    components: { ModalFilesManagement },
    mixins: [BpI18nHandlerMixin],
    props: {
        goodsData: Array,
        initialGoodsData: Object,
        ensTruDict: Array,
        variantAttribute: Boolean,
        currCategory: Object,
        lang: String,
        deleteGood: Function,
        ifAllFieldsFilled: Function,
        getUnitName: Function,
        ensTruNamePrt: Array,
        ensTruName: Array,
        currUnits: Array,
        loadCurUnits: Function,
        getEnstruByName: Function,
        showFileUploadModal: Function,
        triggDownloadFile: Function,
        parNameLoading: Boolean,
        descIsLoading: Boolean,
        allFiles: Array,
        formCode: String,
        idxOfInfl: Number,
        goodsAreSaving: Boolean

    },

    watch: {
        goodsData: {
            handler(newValue, oldValue) {
                this.calcTotals();
            },
            deep: true
        },
        lang: {
            handler(newValue, oldValue) {
                this.setTableFields();
            }
        }
    },

    created() {
        this.setTableFields();
    },

    filters: {
            pointToComma: function (value) {
                if (!value) return ''
                return value.toLocaleString('ru-RU', { maximumFractionDigits: 6 })
            }
    },

    data() {
        return {
            dataTypeFilter: null,
            variantName: null,
            formFilter: null,
            budgetForm: [],
            header: null,
            dict: [],
            files: null,
            load: false,
            openDisabled: false,
            isLoad: false,
            currUnit: null,
            currGoodName: null,
            totalNumMeals: 0,
            totalAvgDays: 0,
            totalMaxDays: 0,
            totalNormMoney: 0,
            totalSpend: 0,
            currGoodDesc: null,
            normDigits: 6,
            normMoneyDigits: this.formCode === '01-141' ? 4 : 2,
            selectAllGoods: false
        };
    },

    methods: {
        addItem() {
            // проверяем все ли поля заполнены            
            if (!this.ifAllFieldsFilled()) return;
            this.currGoodName = null;     
            const item = { 
                id: new Date() * -1,
                isNew: true,
                rowToSave: true,
                currGoodDesc: null,
                esnActive: true,
                num_meals: 0, 
                num_day: 365, 
                norm_per: 0, 
                price_cur: 0, 
                price_indexed: 0, 
                index_inf: this.idxOfInfl, 
                nummealsPassVal: false,
                numdayPassVal: false,
                normperPassVal: false,
                pricecurPassVal: false,
                priceidxedPassVal: false,
                esnActive: true,
                totalNumMealsSaved: 0,
                totalAvgDaysSaved: 0,
                totalNormMoneySaved: 0,
                totalSpendSaved: 0,
                itemToDelete: false
                };  
            this.$emit('prepareData', item);
            this.$emit('setTotals', item);
            this.goodsData.push(item);
            this.ensTruDict.splice(0);
        }, // добавление записи в таблицу

        async setCurrGoodDesc(item) {
            await this.getEnstruByName(this.currGoodName.name);
            process.nextTick(() => {
                if (this.ensTruName.length) {
                    const relativeDecrIdx = this.ensTruName.findIndex(itm => itm.code === this.currGoodName.code);
                    this.$set(item, 'currGoodDesc', this.ensTruName[relativeDecrIdx !== -1 ? relativeDecrIdx : 0]);
                    this.$set(item, 'ensTruName', this.ensTruName);
                    this.descSelectedNameChanged(item);
                };
            })
        },

        makeToast(variant = null, title, msg) {
            this.$bvToast.toast(msg, {
            title: title,
            variant: variant,
            solid: true
            })
        },
        
        changedUnit(item) {
            //  this.$set(item, 'unit_code', this.currUnit.code);
        },

        async descSelectedNameChanged(item) {
            await this.loadCurUnits(item.currGoodDesc.code)
            process.nextTick(() => {
                this.$set(item, 'enstru_code', item.currGoodDesc.code);
                const uom = [];
                this.currUnits.forEach(u => {
                    const unitName = this.getUnitName(u.unit_code);
                    uom.push({name: unitName, code: u.unit_code});
                })
                if (uom.length) {
                    this.currUnit = uom[0];
                } else this.currUnit = [];
                this.$set(item, 'unit_code', this.currUnit.code);
                this.$set(item, 'uom', uom);
                this.$set(item, 'shrDescr', item.currGoodDesc['detail_'+this.lang]);
                this.$set(item, 'name', item.currGoodDesc['name_'+this.lang]);
                this.$emit('onDataChanged', item);
            });
            this.onChange(item, 'norm_per');
        },

        formatText(text) {
            if (text.length > 254) {
                return text.slice(0, 254);
            } else {
                return text;
            }
        },

        inputFixed(item, field, value, digit) {
            this.$nextTick(() => {
                const newVal = parseFloat(value) ? this.safeDecimal(value).toDecimalPlaces(digit).toNumber() : 0;
                this.$set(item, field, newVal);
                this.onChange(item, field);
            })
        }, // форматирует введенное значение до digit цифр после запятой

        onChange(item, field) {
            if (field === 'price_cur' && 'price_cur' in item) {
                const idxOfInfl = this.safeDecimal(this.idxOfInfl);
                const price_cur = this.safeDecimal(item.price_cur);
                const priceIndexed = this.safeDecimal(item.price_cur).mul(idxOfInfl).add(price_cur).toDecimalPlaces(2).toNumber();
                this.$set(item, 'price_indexed', priceIndexed);
            };
                const is_gramms = parseInt(item.unit_code) === 163;
                const coeff = is_gramms ? 1000 : 1;

            const isHasNeededFields = 'norm_per' in item && 'price_indexed' in item;
            if (isHasNeededFields && (field === 'price_cur' || field === 'price_indexed' || field === 'norm_per')) {
                const price_indexed = this.safeDecimal(item.price_indexed);
                const normMoney = this.safeDecimal(item.norm_per).mul(price_indexed).div(coeff).toDecimalPlaces(this.normMoneyDigits).toNumber()
                this.$set(item, 'norm_money', normMoney);
            };
            if (field === 'num_meals' || field === 'num_day ' || field === 'norm_per') {
                const num_day = this.safeDecimal(item.num_day);
                const norm_per = this.safeDecimal(item.norm_per);
                const yearVal = this.safeDecimal(item.num_meals).mul(num_day).mul(norm_per).div(coeff).toDecimalPlaces(3).toNumber();
                this.$set(item, 'year_value', yearVal);
            };
            // this.$set(item, 'total', parseFloat((parseFloat(item.price_indexed) * parseFloat(item.year_value) / 1000).toFixed(3)));
            this.$emit('onDataChanged', item);
        }, // пересчет значений в полях

        keyPress: function (event, pattern) {
            const regex = new RegExp(pattern);
            const key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
            if (!regex.test(key)) {
                event.preventDefault();
                return false;
            }
        }, // вводит по заданному паттерну

        keyup13: function (event) {
            event.preventDefault();
            // Isolate the node that we're after
            const currentNode = event.target;
            // find all tab-able elements
            const allElements = document.querySelectorAll('input'); // area, object, select, [contenteditable]
            // Find the current tab index.
            const currentIndex = [...allElements].findIndex(el => currentNode.isEqualNode(el));
            // select/focus the following element
            const targetIndex = (currentIndex + 1) % allElements.length;
            if (targetIndex < allElements.length) {
                allElements[targetIndex].select();
            }
        }, // enter работает как tab

        deleteItem(msg, row = false, index = false) {
            if (!this.variantAttribute || (!row && this.goodsData.findIndex(itm => itm.itemToDelete) === -1)) return;
            let deletingRow = null;
            this.$bvModal.msgBoxConfirm(
                `${msg}...`,
                {
                    title: this.getCommonText('confirm'),
                    size: 'lg',
                    buttonSize: 'sm',
                    okVariant: 'danger',
                    okTitle: this.getCommonText('yes'),
                    cancelTitle: this.getCommonText('cancel'),
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true
                })
                .then(value => {
                    if (value) {
                        if (row) {
                            if ((!row.id || row.id < 0) && row.rowToSave) this.goodsData.splice(index, 1); 
                            else {
                                deletingRow = this.goodsData.splice(index, 1);
                                this.calcTotals();
                                this.deleteGood([row]);
                            }
                        } else {
                            const existingRowsToDel = [];
                            let totalDeleting = 0;
                            for (let i = this.goodsData.length - 1; i >=0; i--) {
                                if (this.goodsData[i].itemToDelete) {
                                    const rowId = this.goodsData[i].id
                                    if (rowId >= 0) {
                                        existingRowsToDel.push(rowId)
                                        const initialRow = this.initialGoodsData[rowId] 
                                        const curTotal = initialRow.total
                                        totalDeleting += curTotal || 0;
                                    };
                                    this.goodsData.splice(i, 1);
                                };
                            };
                            this.calcTotals();
                            if (!existingRowsToDel.length) { 
                                this.selectAllGoods = false;
                            } else {
                                const roundedTotalDeleting = totalDeleting.toFixed(3);
                                this.$emit('deleteSeveralGoods', existingRowsToDel, roundedTotalDeleting);
                            }
                        }
                    }
                })
                .catch(error => {
                    if (deletingRow) this.goodsData = [...this.goodsData, ...deletingRow].sort((a, b) => a.id - b.id);
                    this.makeToast('danger', this.getErrText('on_del'), error.toString());
                });
        }, // удаление записи

        calcTotals() {
            let sumMeals = this.safeDecimal(0);
            let sumMealsSaved = this.safeDecimal(0);
            let sumDays = this.safeDecimal(0);
            let sumDaysSaved = this.safeDecimal(0);
            let maxDays = 0;
            let maxDaysSaved = 0;
            let sumNormMoney = this.safeDecimal(0);
            let sumNormMoneySaved = this.safeDecimal(0);
            let sumSpends = this.safeDecimal(0);
            let sumSpendsSaved = this.safeDecimal(0);
            for (const row of this.goodsData) {
                const amount = row.num_meals ? row.num_meals : 0;
                sumMeals = sumMeals.add(parseInt(amount));

                const days = row.num_day ? parseInt(row.num_day) : 0;
                sumDays = sumDays.add(days);
                maxDays = maxDays > days ? maxDays : days;
                const money = row.norm_money ? row.norm_money : 0;
                sumNormMoney = sumNormMoney.add(money);
                const spends = row.total ? row.total : 0;
                sumSpends = sumSpends.add(spends);
                if (row.id > 0) {
                    sumMealsSaved = sumMealsSaved.add(parseInt(amount));
                    sumDaysSaved = sumDaysSaved.add(days);
                    sumSpendsSaved = sumSpendsSaved.add(spends);
                    maxDaysSaved = maxDaysSaved > days ? maxDaysSaved : days;
                    sumNormMoneySaved = sumNormMoneySaved.add(money);
                }
            };
            this.totalNumMeals = this.goodsData.length ? sumMeals.div(this.goodsData.length).toDecimalPlaces(0).toNumber() : 0;
            this.totalAvgDays = this.goodsData.length ? sumDays.div(this.goodsData.length).toDecimalPlaces(0).toNumber() : 0;
            this.totalMaxDays = maxDays;
            this.totalNormMoney = 0;
            this.totalSpend = sumSpends.toDecimalPlaces(3).toNumber();
            if (this.formCode === '01-141' && this.totalNumMeals > 0 && this.totalMaxDays > 0) {
                this.totalNormMoney = this.safeDecimal(this.totalSpend).mul(1000).div(this.totalMaxDays).div(this.totalNumMeals).toDecimalPlaces(this.normMoneyDigits).toNumber();
            } else if (this.formCode !== '01-141') {
                this.totalNormMoney = sumNormMoney.toDecimalPlaces(2).toNumber();
            }
            const lngth = this.goodsData.filter(item => item.id > 0).length
            this.totalNumMealsSaved = lngth ? sumMealsSaved.div(lngth).toDecimalPlaces(0).toNumber() : 0;
            this.totalAvgDaysSaved = lngth ? sumDaysSaved.div(lngth).toDecimalPlaces(0).toNumber() : 0;
            this.totalSpendSaved = sumSpendsSaved.toDecimalPlaces(3).toNumber();
            if (this.formCode === '01-141' && this.totalNumMealsSaved > 0 && maxDaysSaved > 0) {
                this.totalNormMoneySaved = this.safeDecimal(this.totalSpendSaved).mul(1000).div(maxDaysSaved).div(this.totalNumMealsSaved).toDecimalPlaces(this.normMoneyDigits).toNumber();
            } else if (this.formCode !== '01-141') {
                this.totalNormMoneySaved = sumNormMoneySaved.toDecimalPlaces(2).toNumber();
            }
        }, 

        getTotal() {
            return { 'totalAll': {
                        totalNumMeals: this.totalNumMeals, 
                        totalAvgDays: this.totalAvgDays,
                        totalNormMoney: this.totalNormMoney, 
                        totalSpend: this.totalSpend
                    }, 
                    'totalSaved': {
                        totalNumMeals: this.totalNumMealsSaved, 
                        totalAvgDays: this.totalAvgDaysSaved,
                        totalNormMoney: this.totalNormMoneySaved, 
                        totalSpend: this.totalSpendSaved
                    }
                    }
        }, // передача тотала данной категории родителю

        onFilesClick(item) {
            this.$refs.modalFilesManagement.showModal(item);
        },

        setNewFileHash(id, codes) {
            for (const good of this.goodsData) {
                if (good.id === id) {
                    this.$set(good, 'file_hash', codes);
                    this.$emit('onDataChanged', good);
                    break;
                }
            }
        },

        roundNumber(input, decimalPlaces) {
            const numberValue = Number(input) || 0;
            const decimalPlacesValue = Number(decimalPlaces) || 0;
            const rank = Math.pow(10, decimalPlacesValue);
            const mult = Math.round(numberValue * rank * 100) / 100;
            const roundedNumber = Math.round(mult) / rank;
            return roundedNumber;
        },

        setTableFields() {
            if (this.formCode === '01-141') {
                this.tableFields.splice(10, 1);
                this.tableFields[12].label = this.getFormText('expense_sum_dec');
                const newFieldsOrder = [this.tableFields[7], this.tableFields[6]];
                this.tableFields.splice(6, 2, ...newFieldsOrder);
                this.tableFields[9].label = this.getCommonText('price');
                this.tableFields[10].label = this.getFormText('monetary_norm');
                this.tableFields[11].label = this.getFormText('annual_volume');
            }
        },

        currInflIdx(item) {
            const pind = this.safeDecimal(item.price_indexed).mul(100);
            return pind.div(item.price_cur).sub(100).toDecimalPlaces(2).toNumber();
        },

        safeDecimal(value) {
            if (value === undefined || value === null || value === '') {
                return new Decimal(0);
            }
            return new Decimal(value);
        }
    },
    computed: {
        idxOfInflInPersent() {
            return this.safeDecimal(this.idxOfInfl).mul(100).toDecimalPlaces(2).toNumber();
        },

        getFormText() {
            return this.setFormText('form_n_141.');
        },

        tableFields() {
            if (this.$i18n.locale) {
                return [
                    {
                        key: 'action',
                        label: ' '
                    },
                    {
                        key: 'index',
                        label: this.getCommonText('sn')
                    },
                    {
                        key: 'good_name',
                        label: this.getCommonText('name')
                    },
                    {
                        key: 'shrt_descr',
                        label: this.getCommonText('short_info')
                    },
                    {
                        key: 'additional_descr',
                        label: this.getCommonText('add_info')
                    },
                    {
                        key: 'unit',
                        label: this.getUnitsText('measure')
                    },
                    {
                        key: 'num_meals',
                        label: this.getFormText('consumer_count')
                    },
                    {
                        key: 'num_day',
                        label: this.getFormText('days_count')
                    },
                    {
                        key: 'norm_per',
                        label: this.getFormText('daily_norm')
                    },
                    {
                        key: 'price_cur',
                        label: this.getFormText('curr_year_price')
                    },
                    {
                        key: 'price_indexed',
                        label: this.getFormText('coef_price')
                    },
                    {
                        key: 'norm_money',
                        label: this.getFormText('monetary_norm')
                    },
                    {
                        key: 'year_value',
                        label: this.getFormText('annual_volume')
                    },
                    {
                        key: 'total',
                        label: this.getFormText('goods_expense_sum')
                    },
                    {
                        key: 'files',
                        label: this.getCommonText('files')
                    },
                    {
                        key: 'more',
                        label: ''
                    },
                    
                ];
            };
            return [];
        }
    }
}
</script>

<style scoped>
.form-03149-multiselect-group {
    display: flex;
}

.form-03149-multiselect-group .spinner-border {
    position: relative;
    right: 50px;
    opacity: 0.3;
    margin: auto;
    color: #7A87A0;
    z-index: 999;
}

.form-03149-spinner {
    display: block;
    margin: auto;
    color: #7A87A0;
}

.goods-form-val-msg {
    font-size: 0.5rem;
    font-style: italic;
    color: red;
}

.ens-item-non-active {
    color: #6087a099;
}

input {
    min-width: 86px;
}

.wrong_idx {
    color: red !important;
}

</style>